@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  font-family: "Ubuntu", sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

:root {
  /* bg color */
  --black: #000;
  --blackTransparent: rgba(0, 0, 0, 0.5);
  --nav-bg: #1c1d1e;
  --nav-bg-transparent: #1c1d1ed9;
  --white-transparent: rgba(217, 217, 217, 0.12);
  --golden: #f8b928;
  --orange: #e95420;

  --terminal-header: #302e2e;
  --terminal-body: #300a24;

  --github-bg: #24292e;
  --github-dark: #1d2125;
  --github-text: #f8f8f8;
  --github-blue: #0366d6;

  --program-bg-p: #343434;
  --program-bg-s: #405714;
  --program-header: #2c2c2c;
  --orange: #df4916;

  /* text color */
  --color-p: #e9e9e9;
  --color-p-dark: #b3b3b3;
  --terminal-p: #405714;

  /* font */
  --p-font: 14px;
}

/* @media (min-width: 1536px) {
  :root {
    --p-font: calc(14px + 2vmin);
  }
} */

body {
  background-color: black;
  color: var(--color-p);
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border: 0 solid #fff;
  border-radius: 20px;
  background: var(--yellow);
}

.focused {
  z-index: 500 !important;
}
